<template>	
	<div class="article-box">
		<div class="header">
			<p>设备信息</p>
			<div>
				<el-button @click="$router.go(-1)" type="primary" round>返回</el-button>
			</div>
		</div>
		
		<div class="box">
			<div class="left"  >
				<p >
					<span>设备ID：</span>
					<span>{{id}}</span>
				</p>
				<p>
					<span>设备类型：</span>
					<span>{{machine_type}}</span>
				</p>
				<p>
					<span>绑定代理：</span>
					<span>{{agent_name}}</span>
				</p>
				<p>
					<span>投放机构：</span>
					<span>{{organ_name}}</span>
				</p>
			</div>
			<div class="center">
				<p>
					<span>设备编号：</span>
					<span>{{machine_number}}</span>
				</p>
				<p>
					<span>注册时间：</span>
					<span>{{register_date}}</span>
				</p>
				<p>
					<span>绑定时间：</span>
					<span>{{insert_time}}</span>
				</p>
				<p>
					<span>投放时间：</span>
					<span>{{launch_time}}</span>
				</p>
			</div>
			<div class="right">
				<p>
					<span>设备code：</span>
					<span>{{code}}</span>
				</p>
				<p>
					<span>注册人：</span>
					<span>{{register_user}}</span>
				</p>
				<p>
					<span>所在城市：</span>
					<span>{{city}}</span>
				</p>
				
			</div>
		</div>
		
		<p>硬件信息</p>
		<div class="box">
			<div class="left">
				<p>
					<span>注册批次：</span>
					<span>{{batch}}</span>
				</p>
				<p>
					<span>遥控器：</span>
					<span>{{register_info.remote_type}}</span>
				</p>
				<p>
					<span>视标：</span>
					<span v-if="register_info.vision">{{register_info.vision=='1'?'单视标':'多视标'}}</span>
				</p>
				<p>
					<span>测温游客模式：</span>
					<span>无</span>
				</p>
			</div>
			<div class="center">
				<p>
					<span>屏幕尺寸：</span>
					<span>{{register_info.osize}}</span>
				</p>
				<p>
					<span>使用场景：</span>
					<span v-if="register_info.otype">{{register_info.otype=='1' ? '家庭版':'自测版'}}</span>
				</p>
				<p>
					<span>测温模式：</span>
					<span>无</span>
				</p>
				
			</div>
			<div class="right">
				<p>
					<span>主板：</span>
					<span>{{register_info.board_type}}</span>
				</p>
				<p>
					<span>人脸识别：</span>
					<span v-if="register_info.is_face">{{register_info.is_face=='1' ? '开启':'关闭'}}</span>
				</p>
				<p>
					<span>测温游客模式：</span>
					<span>无</span>
				</p>
				
			</div>
		</div>
		
	</div>
</template>

<script>
	import {
		machineList
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				register_info:this.$route.params.register_info,
				// otype:this.$route.params.otype,
				// osize:this.$route.params.osize,
				machine_type:this.$route.params.machine_type,
				id: this.$route.params.id,
				agent_name: this.$route.params.agent_name,
				organ_name:this.$route.params.organ_name,
				register_user:this.$route.params.register_user,
				city:this.$route.params.city,
				register_date:this.$route.params.register_date,
				code:this.$route.params.code,
				machine_number:this.$route.params.machine_number,
				launch_time:this.$route.params.launch_time,
				insert_time:this.$route.params.insert_time,
				batch:this.$route.params.batch,
				// remote_type:this.$route.params.remote_type,
				// vision:this.$route.params.vision,
				// board_type:this.$route.params.board_type,
				// is_face:this.$route.params.is_face,
				keyword: '', // 搜索关键字
				machineData: [], // 设备列表
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				infoList: [],
				
			}
		},
		mounted() {
			this.machineSerchList(1) // 获取第一页设备列表
		},
		methods: {
			machineSerchList(currpage) { // 设备列表
				this.loading = true
				const data = {
					id:this.id,
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				machineList(data).then(res => {
					this.machineData = res.data.list // 设备列表		
						console.log(res.data.list)
						this.machineData.forEach(item=>{
				
							
						})
					
					console.log(this.infoList)
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.machineSerchList(val)
				this.currpage = val
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.box{
		display: flex;
		width: 100%;
		background-color: antiquewhite;
		justify-content: space-around;
		
	}
	.header{
		display: flex;
		justify-content: space-between;
	}
	
</style>